import React from 'react';
import 'assets/scss/komsek.scss';

import Footer from 'components/footer';
import Header from 'components/header';
import { EmailLink, PhoneLink } from 'components/links';
import SEO from 'components/seo';

import Map from 'assets/images/mapy.png';

const Contacts = () => (
  <>
    <SEO title="Kontakty" />
    <Header />
    <article>
      <section>
        <div className="container grid-lg">
          <h1>Kontaktní a fakturační údaje</h1>

          <h2>KOMSEK &ndash; provozovna služeb</h2>
          <div className="subsection columns">
            <div className="column col-6 col-md-12">
              <table className="table">
                <tbody>
                  <tr>
                    <th>Název:</th>
                    <td style={{ width: '60%' }}>
                      Česká provincie Kongregace Milosrdných sester sv. Kříže
                      <div style={{ display: 'block', height: '.5rem' }} />
                      stř. KOMSEK &ndash; Praha 4, Ševce Matouše 1857/15
                    </td>
                  </tr>
                  <tr>
                    <th>Adresa:</th>
                    <td>
                      Koperníkova 1446/3<br/>
                      767 01&ensp;Kroměříž<br/>
                      Česká republika
                    </td>
                  </tr>
                  <tr>
                    <th>IČ:</th>
                    <td>00406538</td>
                  </tr>
                  <tr>
                    <th>DIČ:</th>
                    <td>CZ00406538</td>
                  </tr>
                  <tr>
                    <th>Telefonní číslo:</th>
                    <td><PhoneLink /></td>
                  </tr>
                  <tr>
                    <th>E-mail:</th>
                    <td><EmailLink to={'info'} className="text-dark" /></td>
                  </tr>
                  <tr>
                    <th>Číslo bankovního účtu:</th>
                    <td>115-7275560287/0100</td>
                  </tr>
                </tbody>
              </table>
              <div className="toast toast-primary mt-2">
                Na fakturaci a korespondenci prosím uvádějte stř. KOMSEK
                 &ndash; Praha 4, Ševce Matouše 1857/15.
              </div>
            </div>
            <div className="column col-6 col-md-12">
              <a
                href="https://mapy.cz/s/gelabakaru"
                target="_blank" rel="noreferrer"
              >
                <img alt="Mapa" src={Map} className="img-responsive s-rounded"
                  style={{ margin: '0 auto' }} />
              </a>
            </div>
          </div>
        </div>
      </section>
    </article>
    <Footer />
  </>
);

export default Contacts;
